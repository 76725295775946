<template>
  <div>
    <div
      class="d-flex justify-end"
      :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-4'"
      flat
      tile
    >
      <v-row no-gutters cols="3">
        <v-col>
          <v-select
            class="pa-2"
            outlined
            filled
            dense
            label="Order Status"
            :value="selected_order_status"
            v-model="selected_order_status"
            :items="[
            {
              'text':'Refused',
              'value':'refused',
            },
            {
              'text':'Completed',
              'value':'completed',
            },
            {
              'text':'All',
              'value':null,
            }]"
            item-text="text"
            item-value="value"
            @input="(dateRange) => reOrderData()"
          ></v-select>
          </v-col>
          <v-responsive width="66%"></v-responsive>

          <v-responsive width="100%"></v-responsive>
        </v-col>
        <v-col>
          <v-select
            class="pa-2"
            outlined
            filled
            dense
            label="Sales Post"
            :value="selected_driver"
            v-model="selected_driver"
            :items="getDrivers"
            @input="(dateRange) => salesPostChanged(dateRange)"
          ></v-select>
        </v-col>
        <v-col>
          <v-select
            class="pa-2"
            outlined
            filled
            dense
            label="Product"
            :value="product"
            v-model="product"
            :items="products"
            item-text="name"
            item-value="id"
            @input="(dateRange) => salesPostChanged()"
          ></v-select>
        </v-col>
        <v-col>
          <v-select
            class="pa-2"
            outlined
            filled
            dense
            label="Service"
            :value="service"
            v-model="service"
            :items="products"
            item-text="name"
            item-value="id"
            @input="(dateRange) => salesPostChanged()"
          ></v-select>
        </v-col>
        <v-col>
          <v-select
            class="pa-2"
            outlined
            filled
            dense
            label="Payment Method"
            :value="payment_method"
            v-model="payment_method"
            :items="payment_methods"
            item-text="text"
            item-value="id"
            @input="(dateRange) => salesPostChanged()"
          ></v-select>
        </v-col>
        <v-col>
          <v-select
            class="pa-2"
            outlined
            filled
            dense
            label="Origin"
            :value="origin"
            v-model="origin"
            :items="origins"
            item-text="name"
            item-value="id"
            @input="(dateRange) => salesPostChanged()"
          ></v-select>
        </v-col>
        <v-col>
          <v-select
            class="pa-2"
            outlined
            filled
            dense
            label="Magazine"
            :value="selected_magazine"
            v-model="selected_magazine"
            :items="magazines"
            item-text="name"
            item-value="id"
            @input="(dateRange) => salesPostChanged()"
          ></v-select>
        </v-col>
        <v-responsive width="100%"></v-responsive>
        <v-col>
          <v-select
            class="pa-2"
            outlined
            filled
            dense
            label="Delayed Orders"
            :value="delayed_orders"
            v-model="delayed_orders"
            :items="getDelayedOrders"
            @input="(dateRange) => salesPostChanged(dateRange)"
          ></v-select>
        </v-col>
        <v-col>
          <v-select
            class="pa-2"
            outlined
            filled
            dense
            label="Agent"
            :value="selected_agent"
            v-model="selected_agent"
            :items="getAgents"
            @input="(selected_designer) => reOrderData()"
          ></v-select
        ></v-col>
        <v-col>
          <v-select
            class="pa-2"
            outlined
            filled
            dense
            label="Production"
            :value="selected_production"
            v-model="selected_production"
            :items="getProductions"
            @input="(selected_designer) => reOrderData()"
          ></v-select
        ></v-col>
        <v-col>
          <v-select
            class="pa-2"
            outlined
            filled
            dense
            label="Designer"
            :value="selected_designer"
            v-model="selected_designer"
            :items="getDesigners"
            @input="(selected_designer) => reOrderData(dateRange)"
          ></v-select
        ></v-col>

        <v-col>
          <v-select
            class="pa-2"
            outlined
            filled
            dense
            v-model="dateRange"
            @input="(dateRange) => dateRangeChanged(dateRange)"
            :items="[
              'This Month',
              'Last Month',
              'Today',
              'Yesterday',
              'This Year',
              'Last Year',
              'Custom Date',
            ]"
          ></v-select
        ></v-col>
        <v-col>
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                label="Custom Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker range v-model="date" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn text color="primary" @click="dateRangeChanged(date, 'customDate')">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu></v-col
        >
      </v-row>
    </div>
    <v-card>
      <v-card-title>
        {{ translate("Orders") }}
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-data-table
        sort-by.sync="order_status"
        sort-desc.sync="true"
        :search="search"
        :items-per-page="20"
        :headers="headers"
        :items="computedFilteredOrders"
        class="elevation-1"
      >
        <template v-slot:footer.page-text>
          <v-row style="width: 200px">
            <v-col dense
              ><h4>{{ orders.length }} Orders</h4></v-col
            >

            <v-col dense>
              <h4>{{ sum }}</h4>
            </v-col>
          </v-row>
        </template>
        <template v-slot:item.order_status="{ item }">
          <span class="font-weight-medium v-chip v-size--small" :class="item.color"
            ><span class="v-chip__content"> {{ item.order_status }} </span></span
          >
        </template>
        <template v-slot:item.order_created_at="{ item }">
          <span>{{ formatDate(item.order_created_at) }}</span
          >&nbsp;
          <span class="font-weight-medium v-chip v-size--small primary"
            ><span class="v-chip__content">
              {{ timeAgo(item.order_created_at) }}
            </span></span
          >
        </template>
        <template v-slot:item.id="{ item }">
          <v-dialog width="60%">
            <template v-slot:activator="{ on, attrs }">
              <a color="red lighten-2" dark v-bind="attrs" v-on="on">
                {{ item.id }}
              </a>
            </template>
            <v-card>
              <v-card-title class="text-h5 grey lighten-2"
                >{{ translate("Order") }} #{{ item.id }}</v-card-title
              >
              <v-card-text>
                <OrderInfo :currency="currency" :order="item"></OrderInfo>
              </v-card-text>
              <v-divider></v-divider>
            </v-card>
          </v-dialog>
        </template>
        <template v-slot:item.actions="{ item }">
          <router-link :to="{ name: 'edit_order', params: { id: item.id } }">
            <!--
             <v-icon style="color: var(--v-warning-base)" small> mdi-pencil </v-icon>
           -->
          </router-link>
          <v-dialog :retain-focus="false" width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                @click.stop="
                  editing_item = JSON.parse(JSON.stringify(item));
                  item.delete_dialog = true;
                "
                small
                style="color: var(--v-error-base)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-delete
              </v-icon>
            </template>

            <v-card>
              <v-card-title class="text-h5"
                >{{ translate("Are you sure want to delete Order") }}
                {{ item.id }}?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="delete_dialog = false">{{
                  translate("Cancel")
                }}</v-btn>
                <v-btn color="primary" @click="handleDelete(item.id)">{{
                  translate("OK")
                }}</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </v-data-table>
    </v-card>
    <div class="text-center">
      <downloadExcel
        class="elevation-0 v-btn v-size--small v-btn--outlined v-btn--tile"
        :data="orders"
        :fields="{
          order_id: 'id',
          order_status: 'order_status',
          order_created_at: 'order_created_at',
          deadline: 'deadline',
          order_completed_at: 'order_completed_at',
          magazine: 'magazine_name',
          created_by_user_id: 'created_by_user_id',
          driver_id: 'driver_id',
          delivery_fee: 'delivery_fee',
          driver_name: 'driver_name',
          driver_phone_number: 'driver_phone_number',
          client_name: 'client_name',
          client_phone_number: 'client_phone',
          client_address: 'client_address',
          client_lat: 'client_lat',
          client_lng: 'client_lng',
          order_status: 'order_status_formatted',
          reason: 'refuse_reason',
          origin: 'origin_type',
          total_price: 'total_price',
          profit: 'profit',
        }"
      >
          Export Excel   
      </downloadExcel>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { ref } from "@vue/composition-api";
import OrderInfo from "@/views/pages/orders/OrderInfo.vue";
import {
  deleteOrder,
  getApprovedDrivers,
  getCompletedOrders,
  getCurrency,
  getMagazines,
  getOrders,
  getUsers,
} from "@/ApiManager";
import moment from "moment";
import { getOrigins } from "@/ApiManager";
import { getPaymentMethods } from "@/ApiManager";
import { getProducts } from "@/ApiManager";

export default {
  computed: {
    computed_order_statuses() {
      var a = [];
      for (var i = 0; i < this.orders.length; i++)
        a.push({
          value: this.orders[i].order_status,
          text: this.orders[i].order_status_formatted,
        });
      return a;
    },
    sum() {
      var sum = 0;

      for (var i = 0; i < this.orders.length; i++) {
        sum = sum + this.orders[i]["total_price_raw"];
      }
      if (sum == 0) {
        return "";
      }
      return `${sum} Lek`;
    },
    getDelayedOrders() {
      var drivers = [];
      drivers.push({ value: "0", text: "Not Delayed Orders" });

      drivers.push({ value: "1", text: "Delayed Orders" });

      drivers.push({ value: "null", text: "All" });

      return drivers;
    },
    getOrderStatuses() {
      var orderStatuses = [
        "designing",
        "production",
        "waiting_for_a_driver",
        "driver_picking_up",
        "delivering",
        "company_delivering",
        "client_picking_up",
        "returned",
      ];
      var result = [];
      for (var i = 0; i < orderStatuses.length; i++) {
        result.push({
          value: orderStatuses[i],
          text: this.orderStatusToText(orderStatuses[i]),
        });
      }
      return result;
    },
    getAgents() {
      var designers = [];
      for (var i = 0; i < this.users.length; i++) {
        if (["sales_agent", "manager"].includes(this.users[i].role))
          designers.push({ value: this.users[i].id, text: this.users[i].name });
      }
      designers.push({ value: "null", text: "All" });

      return designers;
    },
    getProductions() {
      var designers = [];
      for (var i = 0; i < this.users.length; i++) {
        if (this.users[i].role == "production")
          designers.push({ value: this.users[i].id, text: this.users[i].name });
      }
      designers.push({ value: "null", text: "All" });

      return designers;
    },
    getDesigners() {
      var designers = [];
      for (var i = 0; i < this.users.length; i++) {
        if (this.users[i].role == "designer")
          designers.push({ value: this.users[i].id, text: this.users[i].name });
      }
      designers.push({ value: "null", text: "All" });

      return designers;
    },
    getDrivers() {
      var drivers = [];
      drivers.push({ value: "client_picks_up", text: "Shop Peshkopi" });
      drivers.push({ value: "delivery_company", text: "Addex" });
      for (var i = 0; i < this.drivers.length; i++) {
        if (this.drivers[i].role == "driver")
          drivers.push({ value: this.drivers[i].id, text: this.drivers[i].name });
      }
      drivers.push({ value: "null", text: "All" });

      return drivers;
    },
    computedFilteredOrders() {
      return this.filteredOrders;
    },
  },

  data() {
    return {
      selected_magazine: null,
      magazines: null,
      delayed_orders: null,
      selected_agent: null,
      selected_designer: null,
      selected_production: null,
      dateRange: "This Month",
      date: null,
      menu: null,
      filteredOrders: [],
      selectedDate: null,
      filterDialog: false,
      search: "",
      selected_order_status: null,
      delete_dialog: false,
      selected_driver: null,
      currency: "",
      magazine_names: [],
      single_name: [],
      users: [],
      drivers: [],
      orders: [],
      origin: "null",
      origins: [],
      payment_method: "null",
      payment_methods: [],
      products: [],
      service: "null",
      product: "null",
      headers: [
        {
          align: "start",
          value: "name",
        },
        { text: this.translate("Order Number"), value: "id" },
        { text: this.translate("Created Date"), value: "order_created_at" },
        { text: this.translate("Customer Name"), value: "client_name" },
        { text: this.translate("Magazine"), value: "magazine_name" },
        { text: this.translate("Order Status"), value: "order_status" },
        { text: this.translate("Total Price"), value: "total_price" },
        { text: this.translate("Actions"), value: "actions" },
      ],
    };
  },
  components: {
    OrderInfo,
  },
  methods: {
    reOrderData() {
      this.$store.state.loading = true;
      this.getOrdersLocal();
    },
    salesPostChanged() {
      this.$store.state.loading = true;
      this.getOrdersLocal();
    },
    dateRangeChanged(dateRange, customDate) {
      if (dateRange == "Custom Date" && customDate == undefined) return;
      this.$store.state.loading = true;
      console.log(customDate);
      if (customDate != undefined) {
        this.dateRange = "Custom Date";
      }
      this.getOrdersLocal();
    },
    getOrdersLocal() {
      getCompletedOrders(
        this.selected_driver,
        this.dateRange,
        this.selected_designer,
        this.selected_production,
        this.selected_agent,
        this.delayed_orders,
        this.selected_magazine,
        this.origin,
        this.payment_method,
        this.service,
        this.product
      ).then((response) => {
        console.log(response);
        this.orders = response;
        this.orders.forEach((product) => {
          product.total_price_raw = product.total_price;
          product.total_price = `${product.total_price} ${this.currency}`;
          product.color = this.orderStatusToColor(product.order_status);
          product.order_status_formatted = this.orderStatusToText(product.order_status);
        });
        this.$store.state.loading = false;
        this.filteredOrders = this.orders;
        var selectedOrders=[]
        if(this.selected_order_status!=null){
          for(var i =0;i<this.orders.length;i++){
          if(this.orders[i].order_status==this.selected_order_status){
            selectedOrders.push(this.orders[i])
          }
        }
        this.filteredOrders=selectedOrders;
        }
        
      });
    },

    sameDay(d1, d2) {
      return (
        d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate()
      );
    },
    magazine_selected(magazine_name) {
      for (var i = 0; i < this.magazine_names.length; i++)
        if (this.magazine_names[i].name == magazine_name)
          this.edit_magazine = this.magazine_names[i].id;
    },
    async handleDelete(id) {
     await deleteOrder(id);
      getCurrency().then((response) => {
        this.currency = response["currency"];
      });
      this.delete_dialog = false;
      this.orders = this.orders.filter(function (item) {
        return item.id != id;
      });
    },
  },
  created() {
    this.$store.state.loading = true;
    //this.$store.state.socketio.on('orders_updated', (...args) => {
    //  this.getOrdersLocal()
    //})
    this.getOrdersLocal();

    getCurrency().then((response) => {
      this.currency = response["currency"];
    });

    getMagazines().then((response) => {
      this.magazine_names = response;
    });
    getUsers().then((response) => {
      this.users = response;
      for (var i = 0; i < response.length; i++) {
        var user = response[i];
        if (user.role == "driver") {
          this.drivers.push(user);
        }
      }
    });
    getMagazines().then((response) => {
      this.magazines = response;
    });
    getOrigins().then((response) => {
      this.origins = response;
    });
    getPaymentMethods().then((response) => {
      this.payment_methods = response;
    });
    getProducts().then((response) => {
      this.products = response;
    });
  },
};
</script>

<style>
a {
  text-decoration: none;
}
</style>
